/* eslint react/jsx-indent: 1 */
/* eslint react/jsx-indent-props: 1 */
/* eslint react/no-unused-state: 1 */
/* eslint no-undef: 1 */

import 'react-dates/initialize';
import React from 'react';
import { Logger } from 'aws-amplify';
import {
  SearchInput, PrimaryButton, Pulse, MultiSelectDropDown
} from 'wf-react-component-library';
import { getPurchaseOrderItemDetails } from '../services/api/OCAApiService';
import { putPurchaseOrder } from '../services/api/OCSApiService';
import PurchaseOrderItemDetails from './PurchaseOrderItemDetails';
import PurchaseOrderDetailsLine1 from './PurchaseOrderDetailsLine1';
import PurchaseOrderDetailsLine2 from './PurchaseOrderDetailsLine2';
import ErrorModal from './ErrorModal';
import ConfirmationModal from './ConfirmationModal';
import ResetModal from './ResetModal';
import { validateWeightAndQty } from '../services/validation/purchase-order-details-validation'
import 'react-dates/lib/css/_datepicker.css';
import './PurchaseOrderDetails.scss';
import StickyWrapper from './StickyWrapper';
import getOCSBody from '../services/data-manipulation/oca-data'


const logger = new Logger('PurchaseOrderDetails.js');

class PurchaseOrderDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectAllChecked: false,
      selectedItems: [],
      showOrderItems: {},
      purchaseOrderDetailsOriginal: JSON.parse(JSON.stringify(this.props.purchaseOrderDetails)),
      purchaseOrderDetails: this.props.purchaseOrderDetails,
      searchItems: this.props.purchaseOrderDetails,
      purchaseOrderItemDetails: [],
      customerDetails: this.props.customerDetails,
      buyerDetails: this.props.buyerDetails,
      isSaving: false,
      saveNotSubmit: false,
      displayNoPOsSelectedMessage: false,
      errorMsg: '',
      loadingItemDetails: false,
      showConfirmationModal: false,
      showResetModal: false,
      isDirty: false,
      itemDetailErrors: [],
      qtyErrors: [], // store itemCodes that have qty errors
      priceErrors: [], // store itemCodes that have price errors
      showSelectedPOs: false,
      customerFilter: [],
      buyerFilter: [],
      xpoFilter: null
    };

    this.handlePoHeaderChange = this.handlePoHeaderChange.bind(this);
    this.handlePoItemChange = this.handlePoItemChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleSaveConfirmation = this.handleSaveConfirmation.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleExpandCollapse = this.handleExpandCollapse.bind(this);
    this.handleXpoFilterChange = this.handleXpoFilterChange.bind(this);
    this.handleXpoFilterClear = this.handleXpoFilterClear.bind(this);
    this.handleCustomerFilterChange = this.handleCustomerFilterChange.bind(this);
    this.handleBuyerFilterChange = this.handleBuyerFilterChange.bind(this);
    this.handleSelectAllCheckBoxClick = this.handleSelectAllCheckBoxClick.bind(this);
    this.handleCheckBoxClick = this.handleCheckBoxClick.bind(this);
    this.cancelConfirmationModal = this.cancelConfirmationModal.bind(this);
    this.displayResetModal = this.displayResetModal.bind(this);
    this.cancelResetModal = this.cancelResetModal.bind(this);
    this.handleHideShowSelectedPOs = this.handleHideShowSelectedPOs.bind(this);
  }

  componentDidUpdate = (prevProps) => {
    const { searchId, purchaseOrderDetails } = this.props;
    if (searchId !== prevProps.searchId) {
      if (this.state.isDirty) {
        this.setState({ showResetModal: true });
      } else {
        this.setState({
          purchaseOrderDetailsOriginal: JSON.parse(JSON.stringify(purchaseOrderDetails)),
          purchaseOrderDetails,
          searchItems: purchaseOrderDetails
        });
      }
    }
  }

  componentDidUpdate = (prevProps) => {
    const { searchId, purchaseOrderDetails, customerDetails, buyerDetails } = this.props;
    if (searchId !== prevProps.searchId) {
      this.setState({
        purchaseOrderDetailsOriginal: JSON.parse(JSON.stringify(purchaseOrderDetails)),
        purchaseOrderDetails,
        customerDetails,
        buyerDetails,
        searchItems: purchaseOrderDetails
      })
    }
  }

  handleXpoFilterChange = (e) => {
    const xpoFilter = e.target.value;
    this.setState({ xpoFilter: xpoFilter }, () => this.filterOrdersByCriteria());
  }

  handleXpoFilterClear = () => {
    this.setState({ xpoFilter: null }, () => this.filterOrdersByCriteria());
  }

  handleCustomerFilterChange = (selectedList) => {
    this.setState({ customerFilter: selectedList }, () => this.filterOrdersByCriteria());
  }

  handleBuyerFilterChange = (selectedList) => {
    this.setState({ buyerFilter: selectedList }, () => this.filterOrdersByCriteria());
  }

  filterOrdersByCriteria = () => {
    const { purchaseOrderDetails, xpoFilter, customerFilter, buyerFilter } = this.state;
    if (xpoFilter || customerFilter.length || buyerFilter.length) {
      const customerCodes = customerFilter.map(customer => customer.customerCode);
      const buyerIds = buyerFilter.map(buyer => buyer.buyerId);
      const searchItems = purchaseOrderDetails.filter(po =>
        (xpoFilter ? po.purchaseOrderId.toString().includes(xpoFilter) : true) && 
        (customerCodes.length ? customerCodes.includes(po.customerCode) : true) && 
        (buyerIds.length ? buyerIds.includes(po.buyerId) : true)
      );
      this.setState({ searchItems });
    } else {
      this.setState({ searchItems: purchaseOrderDetails });
    }
  }

  // Changes from Line 1 and Line 2 Child Components
  handlePoHeaderChange = (po) => {
    const { purchaseOrderDetails } = this.state;
    const idx = purchaseOrderDetails.map( po => po.purchaseOrderId ).indexOf(po.purchaseOrderId);

    purchaseOrderDetails[idx] = po;
    this.setState({ purchaseOrderDetails, isDirty: true });
  }

  handlePoItemChange = (poItem) => {
    const { purchaseOrderItemDetails } = this.state;
    const idx = purchaseOrderItemDetails.map( item => item.purchaseOrderDetailId ).indexOf(poItem.purchaseOrderDetailId);

    purchaseOrderItemDetails[idx] = poItem;
    this.setState({ purchaseOrderItemDetails, isDirty: true });
  }

  handleSaveConfirmation = (e) => {
    const action = e.target.innerText
    let { selectedItems, purchaseOrderDetails, purchaseOrderItemDetails, itemDetailErrors, qtyErrors, priceErrors } = this.state;
    let hasErrors = false;
    let removeSelectedItems = [];

    itemDetailErrors = [];
    qtyErrors = [];
    priceErrors = [];

    // check if something selected
    if (selectedItems.length === 0) {
      hasErrors = true;
      this.setState({ errorMsg: `Please select a purchase order before ${action === "Save" ? 'saving' : 'submitting'}.` });
      return;
    }

    // Check for pick up number and cooler code only if submitting POs
    selectedItems.forEach(poNumber => {
      const poDetails = purchaseOrderDetails.filter(po => po.purchaseOrderId.toString() === poNumber.toString());
      
      if (poDetails.length > 0) {
        const po = poDetails[0];

        if (action !== "Save" && !po.pickupNumber && !po.savedPickupNumber) {
          hasErrors = true;
          this.setState({ errorMsg: `In order to submit your confirmation, a pick-up number is required. Please enter the pick-up number and try again.` });
          return;
        }

        //validating for cooler.
        // coolerCode = original value or the newly selected value from the drop down
        // newCoolerCode = will only exist if the user has selected a new value from the drop down
        // savedCoolerCode = will only exist if the user saved a cooler code
        
        // Use Cases on Submit are:
        // 1. User does not change cooler code at all... there will be no newCoolerCode, no savedCoolerCode, on coolerCode
        const coolerCodeIsNullOrEmpty = !po.newCoolerCode && !po.savedCoolerCode && (!po.coolerCode || po.coolerCode === null || po.coolerCode.length === 0);
        
        // 2. User selects a new cooler code from the dropdown
        const newCoolerCodeIsNullOrEmpty = 'newCoolerCode' in po && (po.newCoolerCode === null || po.newCoolerCode.length === 0);

        // 3. User is submitting the saved cooler code, there will be no newCoolerCode but there will be a coolerCode
        // 3a. But the user may have not saved/changed the cooler code
        const savedCoolerCodeIsNullOrEmpty = 'savedCoolerCode' in po && !po.newCoolerCode && (po.savedCoolerCode === null || po.savedCoolerCode.length === 0);

        if (action !== "Save" && (coolerCodeIsNullOrEmpty || newCoolerCodeIsNullOrEmpty || savedCoolerCodeIsNullOrEmpty)) {
          hasErrors = true;
          this.setState({ errorMsg: `In order to submit your confirmation, a cooler is required. Please select a valid cooler and try again..` });
          return;
        } 

        const poItemDetails = purchaseOrderItemDetails.filter(item => item.poId.toString() === poNumber.toString());
        if (poItemDetails.length === 0) {
          hasErrors = true;
          this.setState({ errorMsg: 'Please click the "Show Items" link to verify that all the item(s) for the purchase order(s) are correct' });
          return;
        }

        // Line item validation
        const validation = validateWeightAndQty(po, poItemDetails);
        removeSelectedItems = [...validation.removeSelectedItems, ...removeSelectedItems];
        itemDetailErrors = [...validation.itemDetailErrors, ...itemDetailErrors];
        qtyErrors = [...validation.qtyErrors, ...qtyErrors];
        priceErrors = [...validation.priceErrors, ...priceErrors];
      }
    });

    if (removeSelectedItems.length > 0) {
      const updatedList = selectedItems.filter(item => !removeSelectedItems.includes(item.toString()));
      hasErrors = true;
      this.setState({ itemDetailErrors, qtyErrors, priceErrors });
    }

    if (!hasErrors) 
      (action === "Save") ? this.setState({ showConfirmationModal: true, saveNotSubmit: true }) : this.setState({ showConfirmationModal: true });
  }

  handleSave = () => {
    this.setState({ showConfirmationModal: false, isSaving: true, searchItems: [] });

    const poPromises = [];
    const { selectedItems, purchaseOrderDetails, purchaseOrderItemDetails, purchaseOrderDetailsOriginal, saveNotSubmit } = this.state;
    const { currentUser } = this.props;

    selectedItems.forEach(poNumber => {
      const poDetails = purchaseOrderDetails.filter(po => po.purchaseOrderId.toString() === poNumber.toString());
      const poDetailsOriginal = purchaseOrderDetailsOriginal.filter(po => po.purchaseOrderId.toString() === poNumber.toString());

      if (poDetails.length > 0) {
        const po = poDetails[0];
        const poOriginal = poDetailsOriginal[0];
        const poItemDetails = purchaseOrderItemDetails.filter(item => item.poId.toString() === poNumber.toString());

        const body = getOCSBody(po, poItemDetails, currentUser, poOriginal);

        (saveNotSubmit) ? body.submitted = "false" : body.submitted = "true";
        
        poPromises.push(putPurchaseOrder(po.buyerId, po.purchaseOrderId, body));
      }
    });

    Promise.all(poPromises).then(() => {
      // once completed saving all the POs
      this.setState({ isSaving: false, selectedItems: [], selectAllChecked: false, saveNotSubmit: false });

      // refresh the search criteria
      this.props.handleSearch();
    })
    .catch(() => {
      // in case of issues while saving the POs
      this.setState({ isSaving: false, saveNotSubmit: false });
      this.setState({ errorMsg: `Error occured while ${saveNotSubmit ? 'saving' : 'submitting' } POs. Please try again.`})
    });
  }

  displayResetModal = () => {
    this.setState({ showResetModal: true });
  }

  cancelResetModal = () => {
    this.setState({ showResetModal: false });
  }

  handleCancel = () => {
    this.setState({ showResetModal: false, isDirty: false });
    this.props.handleSearch();
  }

  removeErrorMessages = () => this.setState({ errorMsg: '' });

  handleSelectAllCheckBoxClick = () => {
    const { selectAllChecked, searchItems } = this.state;
    if (selectAllChecked) {
      this.setState({
        selectedItems: [],
        selectAllChecked: false
      });
    } else {
      const selectedItems = searchItems.map((item) => item.purchaseOrderId.toString());
      this.setState({
        selectAllChecked: true,
        selectedItems
      });
    }
  };

  handleCheckBoxClick = (e) => {
    const currentItem = e.target.value.toString();
    var { selectedItems } = this.state;

    if (selectedItems.includes(currentItem)) {
      // remove from the array
      selectedItems = selectedItems.filter((item) => item !== currentItem);
      this.setState({ selectedItems, selectAllChecked: false });
    } else {
      selectedItems.push(currentItem);
      this.setState({
        selectedItems,
        selectAllChecked: false
      });
    }
  };

  handleExpandCollapse = async (poNumber) => {
    var { showOrderItems, purchaseOrderItemDetails, purchaseOrderDetails } = this.state;

    const itemDetails = purchaseOrderItemDetails.filter(item => item.poId === poNumber);

    if (itemDetails.length === 0) {
      this.setState({ loadingItemDetails: true, loadingItemDetailsFor: poNumber });
      const poDetail = purchaseOrderDetails.find(po => po.purchaseOrderId === poNumber);
      const savedPoItemDetails = (poDetail !== undefined && poDetail.savedPoDetail) ? poDetail.savedPoDetail : [];
      
      const details = await getPurchaseOrderItemDetails(poNumber);
      if (savedPoItemDetails.length > 0) {
        for (const savedDetail of savedPoItemDetails) {
          for(const detail of details) {
            if (savedDetail.itemCode === detail.item.itemCode) {
              for (const key in savedDetail) {
                detail[key] = savedDetail[key]
              }
              break
            }
          }
        }
      }
      purchaseOrderItemDetails = purchaseOrderItemDetails.concat(details);
    }
    //Remove any line item with 0 quantity
    purchaseOrderItemDetails = purchaseOrderItemDetails.filter((item) => item.arQty > 0);
    //External vendors wanted the items to be sorted based on p3 item code.
    purchaseOrderItemDetails.sort((a, b) => (a.item.itemCode > b.item.itemCode) ? 1 : -1);
    showOrderItems[poNumber] = !showOrderItems[poNumber];

    this.setState({ showOrderItems, purchaseOrderItemDetails, loadingItemDetails: false });
  }

  cancelConfirmationModal = () => {
    this.setState({ showConfirmationModal: false, saveNotSubmit: false });
  }

  handleHideShowSelectedPOs = () => {
    const { showSelectedPOs } = this.state;
    this.setState({ showSelectedPOs: !showSelectedPOs});
  }

  render() {
    var {
      selectAllChecked,
      selectedItems,
      purchaseOrderItemDetails,
      isSaving,
      saveNotSubmit,
      loadingItemDetailsFor,
      loadingItemDetails,
      showConfirmationModal,
      showResetModal,
      searchItems,
      customerDetails,
      buyerDetails,
      itemDetailErrors,
      qtyErrors,
      priceErrors,
      showSelectedPOs,
    } = this.state;

    const { vendorCoolers, countryOfOrigins, searchId } = this.props;

    const divElement = document.querySelector(".search-criteria");
    const offsetHeight = divElement === null ? 5: divElement.offsetHeight + 22 + 5;

    searchItems = showSelectedPOs && selectedItems ? searchItems.filter((item) => selectedItems.includes(item.purchaseOrderId.toString())) : searchItems;

    return (
        <div>
            { this.state.errorMsg.length > 0
              ? <ErrorModal showErrorModal={this.state.errorMsg.length > 0} errorMessages={this.state.errorMsg} closeModal={this.removeErrorMessages} />
              : null
            }

            { isSaving
              ? <StickyWrapper offset={offsetHeight}>
                  <div className="flex-container-row">
                      <Pulse text='Saving PO Data....' />
                  </div>
                </StickyWrapper>
              : null
            }

            { showConfirmationModal && selectedItems.length > 0
              ? <ConfirmationModal showConfirmationModal={showConfirmationModal} selectedItems={selectedItems} saveNotSubmit={saveNotSubmit} handleSave={this.handleSave} cancelConfirmationModal={this.cancelConfirmationModal} />
              : null
            }  
            
            { showResetModal
              ? <ResetModal showResetModal={showResetModal} handleReset={this.handleCancel} cancelResetModal={this.cancelResetModal} />
              : null
            }
            
            <StickyWrapper offset={offsetHeight}>
                { !isSaving
                  ? <div className="flex-container-row actions-bar show">
                      <div className="flex-container-column">
                          <div className="flex-container-row search-container">
                              <SearchInput id="xpo-search" clearSearch={this.handleXpoFilterClear} error={this.errorMsg} handleChange={this.handleXpoFilterChange} placeHolderText="Search By XPO Number" />
                          </div>
                      </div>
                      <div className="flex-container-column">
                        <div className="flex-container-row">
                          <div className="flex-container-column">
                            Buyers: 
                          </div>
                          <div className="flex-container-column select-buyers">
                            <MultiSelectDropDown placeHolderText="Select a Buyer" options={buyerDetails} displayValue="description" id="buyers" onSelect={this.handleBuyerFilterChange} onRemove={this.handleBuyerFilterChange} />
                          </div>
                        </div>
                      </div>
                      <div className="flex-container-column">
                        <div className="flex-container-row">
                          <div className="flex-container-column">
                            Customers: 
                          </div>
                          <div className="flex-container-column select-customers">
                            <MultiSelectDropDown placeHolderText="Select a Customer" options={customerDetails} displayValue="description" id="customers" onSelect={this.handleCustomerFilterChange} onRemove={this.handleCustomerFilterChange} />
                          </div>
                        </div>
                      </div>
                      <div className="flex-container-column">
                            <div className="flex-container-row">
                                <PrimaryButton onClick={this.handleSaveConfirmation} name="Save" />
                                <PrimaryButton onClick={this.handleSaveConfirmation} name="Submit" />
                                <PrimaryButton onClick={this.displayResetModal} name="Cancel" />
                            </div>
                        </div>
                    </div>
                    : null
                }

                { !isSaving
                    ? <div className="flex-container-row select-all-checkbox">
                        <div className="flex-container-column xpo-count">
                          <div className="flex-container-row">
                            <div className="flex-container-column">{searchItems.length} XPOs Returned</div>
                            { (selectedItems && selectedItems.length > 0)
                              ? <div className="flex-container-column">
                                <button className="toggle-search-bar" type="button" onClick={this.handleHideShowSelectedPOs}>{ showSelectedPOs ? 'Show All POs' : 'Show Selected POs Only' }</button>
                              </div>
                              : null
                            }
                          </div>
                        </div>  
                        <div className="flex-container-column">
                            <div className="checkbox-container">
                                <label className="body-short-form" htmlFor={1}>
                                    Select All &nbsp;
                                    <input
                                        type="checkbox"
                                        id={1}
                                        value={1}
                                        checked={selectAllChecked}
                                        onChange={this.handleSelectAllCheckBoxClick}
                                      />
                                    <span />
                                </label>
                            </div>
                        </div>
                    </div>
                    : null
                  }

                  <div className="item-error-messages">
                    { !isSaving && itemDetailErrors.length > 0
                      ? itemDetailErrors.map((error, index) => { return (<div key={index} className="error-msg">{error}</div>) })
                      : null
                    }
                  </div>
              </StickyWrapper>

            {searchItems.map((po) => {

            const originalPo = this.state.purchaseOrderDetailsOriginal.find(podo => podo.purchaseOrderId.toString() === po.purchaseOrderId.toString());

            return (
                <div className="flex-container-row po-details" key={po.purchaseOrderId}>

                    <PurchaseOrderDetailsLine1 searchId={Math.floor(1000000 * Math.random()) * 100000000} handlePoHeaderChange={this.handlePoHeaderChange} searchItems={searchItems} selectedItems={selectedItems} handleCheckBoxClick={this.handleCheckBoxClick} po={po} originalPo={originalPo} selectAllChecked={selectAllChecked} vendorCoolers={vendorCoolers} customer={customerDetails.filter(c => c.customerCode === po.customerCode)[0]} />

                    <PurchaseOrderDetailsLine2 searchId={Math.floor(1000000 * Math.random()) * 100000000} buyer={buyerDetails.filter(buyer => buyer.buyerId === po.buyerId)} purchaseOrderItemDetails={purchaseOrderItemDetails} handleExpandCollapse={this.handleExpandCollapse} handlePoHeaderChange={this.handlePoHeaderChange} po={po} originalPo={originalPo} />

                    { loadingItemDetails && po.purchaseOrderId === loadingItemDetailsFor
                      ? <Pulse text="Loading Item Details...." />
                      : null
                    }

                    { this.state.showOrderItems[po.purchaseOrderId]
                      ? <PurchaseOrderItemDetails itemErrors={{qtyErrors, priceErrors}} searchId={searchId} handlePoItemChange={this.handlePoItemChange} countryOfOrigins={countryOfOrigins} itemDetails={purchaseOrderItemDetails.filter(item => item.poId === po.purchaseOrderId)} />
                      : null }

                </div>
            )}
          )}
        </div>
    );
  }
}

export default PurchaseOrderDetails;
