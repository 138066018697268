import React from 'react';
import { PrimaryButton, Modal } from 'wf-react-component-library';

import './ErrorModal.scss';

const ErrorModal = (props) => (
  <div className="error-modal">
    <Modal show={props.showErrorModal} width="75%">
      <div className="flex-container-row item-details-container show">
        <div className="flex-container-row row-details">
          {props.errorMessages}
        </div>

        <div className="flex-container-row buttons">
          <PrimaryButton onClick={props.closeModal} name="Close" />
        </div>
      </div>
    </Modal>
  </div>
);

export default ErrorModal;
