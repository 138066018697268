import getOriginalCountryOfOrigin from '../../utilities/country-of-origin';
import { hasPoHeaderUpdate, 
    hasVendorPoUpdate, 
    hasNewQty, 
    hasNewPrice, 
    hasNewCountryOfOrigin,
    hasNewLoadDate,
    hasNewCooler,
    hasNewArrivalDate,
    hasSavedPrice,
    hasSavedQty,
    hasSavedCountryOfOrigin,
    hasSavedLoadDate,
    hasSavedArrivalDate,
    hasSavedCooler
} from '../validation/vendor-update-check';

const getOCSBody = (po, poItemDetails, currentUser, poOriginal) => {
    const poItemUpdates = poItemDetails.map((item) => {
        const originalCoo = getOriginalCountryOfOrigin(item);
        let retVal = {
            poDetailId: item.purchaseOrderDetailId,
            itemCode: item.item.itemCode,
            originalQty: item.qty,
            qty: hasNewQty(item) ? parseInt(item.newQty) : hasSavedQty(item) ? parseInt(item.savedQty) : null,
            originalCountryOfOrigin: originalCoo,
            countryOfOrigin: hasNewCountryOfOrigin(item) ? item.item.newCountryOfOrigin : hasSavedCountryOfOrigin(item) ? item.savedCoo : null,
            originalPrice: item.price,
            price: hasNewPrice(item) ? parseFloat(item.newPrice).toFixed(2) : hasSavedPrice(item) ? parseFloat(item.savedPrice).toFixed(2) : null,
            description: item.item.description,
            hi: item.item.palletHigh,
            ti: item.item.palletTie,
            unitWeight: item.item.grossWeight,
            unitType: item.unitType,
            cartonPallet: item.cartonPallet,
            groupCode: item.item.itemGroupCode
        }

        // check if qty, price, coo or totalwWieght is null and remove them from the object if they are
        // OCD is expecting these properties only if the vendor made changes.
        if (retVal.qty === null) delete retVal.qty;
        if (retVal.price === null) delete retVal.price;
        if (retVal.countryOfOrigin === null) delete retVal.countryOfOrigin;

        return retVal;
    });

    const formatDate = (dt) => {
        return (typeof(dt) === 'string' && dt.includes('T')) ? dt.split('T')[0] : dt;
    }

    let body =  {
        vendorId: po.vendorCode,
        poDetail: JSON.stringify(poItemUpdates),
        loadDate: hasNewLoadDate(po, poOriginal) ? formatDate(po.loadDate) : hasSavedLoadDate(po, poOriginal) ? formatDate(po.savedLoadDate) : formatDate(poOriginal.loadDate),
        originalLoadDate: formatDate(poOriginal.loadDate),
        arrivalDate: hasNewArrivalDate(po, poOriginal) ? formatDate(po.arrivalDate) : hasSavedArrivalDate(po, poOriginal) ? formatDate(po.savedArrivalDate) : formatDate(poOriginal.arrivalDate),
        originalArrivalDate: formatDate(poOriginal.arrivalDate),
        createdBy: currentUser,
        customerId: po.customerCode,
        pickupNumber: po.pickupNumber,
        originalPickupNumber: poOriginal.pickupNumber,
        coolerCode: hasNewCooler(po, poOriginal) ? po.coolerCode : hasSavedCooler(po, poOriginal) ? po.savedCoolerCode : po.coolerCode,
        originalCoolerCode: poOriginal.coolerCode,
        originalTotalWeight: po.totalWeight.toString(),
        originalTotalCost: po.totalCost.toString(),
        originalTotalCases: po.totalCartons.toString(),
        originalTotalPallets: po.totalPallets.toString(),
        note: po.note ? po.note : null,
        vendorNote: po.vendorNote ? po.vendorNote : null,
        ocaVendorNote: po.ocaVendorNote ? po.ocaVendorNote : null,
        hasVendorChanges: hasPoHeaderUpdate(po, poOriginal) || hasVendorPoUpdate(poItemDetails)
    };

    return body;
}

export default getOCSBody;