/* eslint react/no-unused-state: 1 */
/* eslint no-undef: 1 */

import 'react-dates/initialize';
import React from 'react';
import { Logger, Auth } from 'aws-amplify';
import { getP3Data } from '../services/api/OCAApiService';
import { keyValueMapper } from '../utilities/key-value-util';

import 'react-dates/lib/css/_datepicker.css';
import './Dashboard.scss';

import Search from './Search';

const logger = new Logger('Dashboard.js');

class Dashboard extends React.Component {
  constructor() {
    super();

    this.state = { 
      countryOfOrigins: {},
      vendorCoolers: {},
      customerDetails: []
    };
  }

  componentDidMount = async () => {
    await Auth.currentAuthenticatedUser().then((user) => {
      logger.info('USER INFO:', user.signInUserSession.idToken.payload);
      this.setState({ currentUser: user.signInUserSession.idToken.payload.email});
    })
    .catch((error) => {
      logger.error('User is not authenticated ', error);
      throw error;
    });
    
    getP3Data().then((resp) => {
      logger.debug('p3Data', resp);
      const coolers = resp.coolers;
      const coolersReduced = coolers.map(cooler => {
        return {
          code: cooler.code,
          coolerText: `${cooler.code} - ${cooler.address1}, ${cooler.city}, ${cooler.state} ${cooler.zip}`
        }
      })
      const vendorCoolers = keyValueMapper(
        coolersReduced,
        'code',
        'coolerText',
      );
      logger.debug('coolers mapped', vendorCoolers);
      const countryOfOrigins = keyValueMapper(
        resp.countryOfOrigins,
        'name',
        'name',
      );
      logger.debug('coo mapped', countryOfOrigins);
      const customerDetails = resp.customers;
      const buyerDetails = resp.buyers;
      const vendorInfo = resp.vendor
      this.setState({ customerDetails, buyerDetails, vendorInfo, vendorCoolers, vendorCoolersDetails: resp.coolers, countryOfOrigins, cooDetails: resp.countryOfOrigins });
    });
  }

  render() {
    const {
      countryOfOrigins, vendorCoolers, currentUser, customerDetails, buyerDetails, vendorInfo
    } = this.state;

    return (
      <div className="App">
        { customerDetails.length === 0 || buyerDetails.length === 0
          ? null
          : <Search customerDetails={customerDetails} buyerDetails={buyerDetails} vendorDetails={vendorInfo} currentUser={currentUser} countryOfOrigins={countryOfOrigins} vendorCoolers={vendorCoolers} />
        }
      </div>
    );
  }
}

export default Dashboard;
