import React from 'react';
import Amplify, { Logger } from 'aws-amplify';
import { SecuredVendorApp } from '@wfm-component-lib/auth';

import Dashboard from './components/Dashboard';
import appConfig from './configuration/AppConfig';

import './App.scss';
import '@wfm-component-lib/auth/dist/index.css';

Amplify.Logger.LOG_LEVEL = appConfig.LOG_LEVEL || 'ERROR';
const logger = new Logger('App.js');

Amplify.configure(appConfig);
logger.debug('Amplify.configure', appConfig);

const app = () => (
  <SecuredVendorApp
    appName={appConfig.appName}
    requiredCognitoGroups={appConfig.requiredCognitoGroups}
    corporateIdEnabled={false}>
    <Dashboard />
  </SecuredVendorApp>
);

export default app;
