const FLOAT_VALIDATION = /^\d+(\.\d{0,2})?$/;

const validateBurdenItem = (details) => {
    return (details.item ? ((details.item.itemGroupCode === "M" || details.item.itemGroupCode === "MB" || details.item.itemGroupCode === "SB") ? true : false ) : false);
}

const hasPropWithValue = (item, prop) => {
  const hasProp = prop in item;
  if (hasProp && (item[prop] !== null && item[prop].length === 0)) return false;

  return true;
}

const validateItem = (details) => {
  let itemDetailErrors = [];
  let qtyErrors = [];
  let weightErrors = [];
  let priceErrors = [];
  let removeSelectedItems = [];
  let msg = '';

  const qty = details.newQty !== undefined ? details.newQty : null;
  const weight = details.newTotalWeight !== undefined ? details.newTotalWeight : null;
  const price = details.newPrice !== undefined ? details.newPrice : null;
  let pattern = FLOAT_VALIDATION; // validate positive floating value to 2 decicmals
  try {
    msg = `The following Purchase Order: ${details.poId}, could not be saved/submitted because item: ${details.item.itemCode} has the following error(s):`;
  }
  catch(er) {
    msg = `The following Purchase Order: ${details.poId}, could not be saved/submitted because of the following error(s)`;
  }
  let errorMsgs = [];

  // validate quantity is positive integer
  if (!hasPropWithValue(details, 'newQty') || ((qty !== null) && (qty.toString().includes('.') || !(qty >>> 0 === parseFloat(qty))))) {
    removeSelectedItems.push(details.poId.toString());
    errorMsgs.push('invalid quantity');
    try {
      qtyErrors.push(details.purchaseOrderDetailId.toString());
    } catch(er) {}
  }

  // validate weight is positive float
  if (!hasPropWithValue(details, 'newTotalWeight') || ((weight !== null) && (!pattern.test(weight)))) {
    removeSelectedItems.push(details.poId.toString());
    errorMsgs.push('invalid weight');
    try {
      weightErrors.push(details.purchaseOrderDetailId.toString());
    } catch(er) {}
  }

  // validate price is a positive float
  if (!hasPropWithValue(details, 'newPrice') || ((price !== null) && (!pattern.test(price)))) {
    removeSelectedItems.push(details.poId.toString());
    errorMsgs.push('invalid price');
    try {
      priceErrors.push(details.purchaseOrderDetailId.toString());
    } catch(er) {}
  }

  if (errorMsgs.length > 0) {
    itemDetailErrors.push(`${msg} ${errorMsgs.join(', ')}`);
  }

  return {
    itemDetailErrors,
    qtyErrors,
    weightErrors,
    priceErrors,
    removeSelectedItems
  }
}

const validateWeightAndQty = (po, poItemDetails) => {
    let itemDetailErrors = [];
    let qtyErrors = [];
    let weightErrors = [];
    let priceErrors = [];
    let removeSelectedItems = []
    
    if (poItemDetails.length > 0) {
      poItemDetails.forEach(details => {
        const validation = validateItem(details);
        removeSelectedItems = [...removeSelectedItems, ...validation.removeSelectedItems];
        itemDetailErrors = [...itemDetailErrors, ...validation.itemDetailErrors];
        weightErrors = [...weightErrors, ...validation.weightErrors];
        qtyErrors = [...qtyErrors, ...validation.qtyErrors];
        priceErrors = [...priceErrors, ...validation.priceErrors];
      })
    }

    return {
        itemDetailErrors,
        qtyErrors,
        weightErrors,
        priceErrors,
        removeSelectedItems
    }
}

export { validateWeightAndQty, validateBurdenItem, FLOAT_VALIDATION, validateItem, hasPropWithValue };