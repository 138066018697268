/* eslint react/jsx-indent: 1 */
/* eslint react/jsx-indent-props: 1 */
/* eslint react/no-unused-state: 1 */
/* eslint no-undef: 1 */

import 'react-dates/initialize';
import React from 'react';
import moment from 'moment';
import { Logger } from 'aws-amplify';
import { SingleDatePicker } from 'react-dates';
import {
  DropDown
} from 'wf-react-component-library';

import 'react-dates/lib/css/_datepicker.css';
import './PurchaseOrderItemDetails.scss';
import { 
	hasLoadDateUpdate,
	hasArrivalDateUpdate,
	hasCoolerUpdate
} from '../services/validation/vendor-update-check';

const logger = new Logger('PurchaseOrderDetails.js');

class PurchaseOrderDetailsLine1 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      po: this.props.po,
      focused: false
    };
    
    this.handleCoolerCodeChange = this.handleCoolerCodeChange.bind(this);
    this.formatDate = this.formatDate.bind(this);
    this.handleCheckBoxClick = this.handleCheckBoxClick.bind(this);
  }

  componentDidUpdate = (prevProps) => {
    const { searchId, po } = this.props;
    if (searchId !== prevProps.searchId) {
      this.setState({
        po
      });
    }
  }

  handleCoolerCodeChange = (e) => {
    const { po } = this.state;
    po.coolerCode = e.target.value;
    po.newCoolerCode = e.target.value;
    this.setState({ po });
    this.props.handlePoHeaderChange(po);
  }

  handleCheckBoxClick = (e) => {
    this.props.handleCheckBoxClick(e);
  }

  formatDate = (deliveryDate) => {
    const dt = moment(deliveryDate);
    return dt.format('MM/DD/YYYY');
  }

  render() {
    const { po } = this.state;
    const { originalPo, selectAllChecked, selectedItems, vendorCoolers, customer } = this.props;

    return (
        <div className="flex-container-row po-data po-item-count">
            <div className="flex-container-column top-level">
                <div className="flex-container-row po-details-2">
                    <div className="flex-container-column">
                        PO#: {po.purchaseOrderId}
                    </div>
                    <div className="flex-container-column po-header-label">
                        Load Date:
                    </div>
                    <div className="flex-container-column">
                        <div className={((po.savedLoadDate !== undefined && po.savedLoadDate === null) || po.loadDate === null) ? 'po-error' : hasLoadDateUpdate(po, originalPo) ? 'date-updated' : ''}>
                        <SingleDatePicker
                            date={(po.savedLoadDate !== undefined && po.savedLoadDate !== null) ? moment(po.savedLoadDate) : moment(po.loadDate)} // momentPropTypes.momentObj or null,
                            onDateChange={(dt) => {
                                const { po } = this.state;
                                po.loadDate = dt.format('YYYY-MM-DD');
                                if (po.savedLoadDate) {po.savedLoadDate = dt.format('YYYY-MM-DD');}
                                this.setState({ po });
                                this.props.handlePoHeaderChange(po);
                            }}
                            focused={this.state.focused} // PropTypes.bool
                            onFocusChange={({ focused }) => this.setState({ focused })}
                            id={`load-dt-${po.purchaseOrderId}`} // PropTypes.string.isRequired,
                            placeholder=""
                            readOnly={true}
                            isOutsideRange={(day) => {
                                if (moment(po.arrivalDate).isBefore(moment().subtract(1, 'day'))) {
                                    return day.isBetween(moment().subtract(1, 'year'), moment().add(1, 'year'));
                                } else {
                                    return !day.isBetween(moment(), moment(po.arrivalDate).add(1, 'day'));
                                }
                            }}
                        />
                        </div>
                    </div>
                    <div className="flex-container-column">
                        {po.dlv === null || po.dlv === false
                        ? <span>Delivery Type: FOB</span>
                        : <span>Delivery Type: DBV</span>
                    }
                    </div>
                    <div className="flex-container-column">
                        {po.dlv === null || po.dlv === false
                        ? <span>Delivery Date: {(po.savedArrivalDate !== undefined && po.savedArrivalDate !== null) ? this.formatDate(po.savedArrivalDate) : this.formatDate(po.arrivalDate)}</span>
                        : <span className={hasArrivalDateUpdate(po, originalPo) ? 'date-updated' : ''}>Delivery Date: <SingleDatePicker
                            date={(po.savedArrivalDate !== undefined && po.savedArrivalDate !== null) ? moment(po.savedArrivalDate) : moment(po.arrivalDate)} // momentPropTypes.momentObj or null,
                            onDateChange={(dt) => {
                                const { po } = this.state;
                                po.arrivalDate = dt.format('YYYY-MM-DD');
                                if (po.savedArrivalDate) {po.savedArrivalDate = dt.format('YYYY-MM-DD');}
                                this.setState({ po });
                                this.props.handlePoHeaderChange(po);
                            }}
                            focused={this.state.focused1} // PropTypes.bool
                            onFocusChange={({ focused}) => this.setState({ focused1: focused })}
                            id={`delivery-dt-${po.purchaseOrderId}`} // PropTypes.string.isRequired,
                            placeholder=""
                            readOnly={true}
                            isOutsideRange={(day) => {
                                if (moment(po.loadDate).isAfter(moment().subtract(1, 'day'))) {
                                    return day.isBefore(po.loadDate);
                                } else {
                                    return day.isBefore(moment().subtract(1, 'day'));
                                }
                            }}
                        /></span>}
                    </div>
                    <div className="flex-container-column po-header-label">
                        Cooler:
                    </div>
                    <div className={hasCoolerUpdate(po, originalPo) ? 'flex-container-column coolers updated' : 'flex-container-column coolers'}>
                        <DropDown id="cooler" value={(po.savedCoolerCode !== undefined && po.savedCoolerCode !== null) ? po.savedCoolerCode : po.coolerCode || ''} options={vendorCoolers} placeHolderText="Select a Cooler" handleChange={this.handleCoolerCodeChange} />
                    </div>
                </div>
                <div className="flex-container-row po-details-ship-to">
                        Ship to: {customer.description} - {`${po.shipAddress.address1}, ${po.shipAddress.city}, ${po.shipAddress.state} ${po.shipAddress.zipCode}, ${po.shipAddress.country}`}
                </div>
            </div>

            <div className="flex-container-column item-count top-level">
                <div className="checkbox-container">
                    <label className="body-short-form" htmlFor={po.purchaseOrderId}>
                        <input
                            data-testid={`checkbox-${po.purchaseOrderId}`}
                            type="checkbox"
                            id={po.purchaseOrderId}
                            value={po.purchaseOrderId}
                            checked={
                                selectAllChecked ||
                                selectedItems.includes(po.purchaseOrderId.toString())
                            }
                            onChange={this.handleCheckBoxClick}
                        />
                        <span />
                    </label>
                </div>
            </div>
        </div>
    )}
}

export default PurchaseOrderDetailsLine1;
