import React from 'react';

import './StickyWrapper.scss';

class StickyWrapper extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const style = {};
    // use position prop to determine whether contents should be stick to top/bottom of container
    // default to top if no position is specified.
    this.props.position
      ? style[this.props.position] = 0
      : style.top = 0;

    this.props.offset
      ? style.top = this.props.offset
      : style.top = 0;

    return (
      <div className="sticky-wrapper" style={style}>
        { this.props.children}
      </div>
    );
  }
}

export default StickyWrapper;
