/* eslint-disable */
const domain = () => {
    const stage = process.env.REACT_APP_STAGE
    let domain = ''
    if (stage.indexOf('dev') > -1)
        domain = '.vma-gdps-d.wfm.global'
    else if (stage.indexOf('tst') > -1)
        domain = '.vma-gdps-t.wfm.global'
    else if (stage === 'qa')
        domain = '.vma-gdps-q.wfm.global'
    else if (stage === 'prd')
        domain = '.vma-gdps.wfm.global'
    else
        domain = '.vma-gdps-d.wfm.global'
    return domain;
}
const appConfig = {
    LOG_LEVEL: process.env.REACT_APP_LOG_LEVEL,
    appName: "Order Confirmation Application",
    requiredCognitoGroups: [
        'external-vendor'
    ],
    groupToSkipVerification: `${process.env.REACT_APP_AWS_USER_POOL_ID}_${process.env.REACT_APP_OAUTH_AAD_PROVIDER_NAME}`,
    "aws_project_region": process.env.REACT_APP_AWS_REGION,
    "aws_cognito_identity_pool_id": process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    "aws_cognito_region": process.env.REACT_APP_AWS_REGION,
    "aws_user_pools_id": process.env.REACT_APP_AWS_USER_POOL_ID,
    "aws_user_pools_web_client_id": process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    authenticationFlowType: 'CUSTOM_AUTH',
    oauth: {
        domain: process.env.REACT_APP_OAUTH_DOMAIN,
        mandatorySignIn: true,
        region: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
        identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
        scope: ["openid", "profile", "email", "aws.cognito.signin.user.admin"],
        redirectSignIn: process.env.REACT_APP_OAUTH_REDIRECT_SIGN_IN,
        redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECT_SIGN_OUT,
        responseType: "code",
        label: process.env.REACT_APP_OAUTH_COGNITO_BUTTON_LABEL,
        options: {
            provider: process.env.REACT_APP_OAUTH_AAD_PROVIDER_NAME
        }
    },
    Auth: (process.env.REACT_APP_STAGE === "local") ? undefined : {
        cookieStorage: {
            domain: domain(),
            path: '/',
            expires: 1,
            sameSite: "lax",
            secure: true
        }
    },
    API: {
        endpoints: [
            {
                name: "OCSApiProxy",
                endpoint: process.env.REACT_APP_OCS_URL
            },
            {
                name: "OCAApiProxy",
                endpoint: process.env.REACT_APP_OCA_URL
            }
        ]

    }
};

export default appConfig;