const getOriginalCountryOfOrigin = (item) => {
    const val =  item.item && item.item !== null
        ? (item.overrideOriginName === null 
            ? item.item.originName
            : item.overrideOriginName) 
        : (item.overrideOriginName === null 
            ? ''
            : item.overrideOriginName);
    return val;
}

export default getOriginalCountryOfOrigin;