import React from 'react';
import { PrimaryButton, SecondaryButton, Modal } from 'wf-react-component-library';

import './ResetModal.scss';

const ResetModal = (props) => (
  <div className="confirmation-modal">
    <Modal show={props.showResetModal} width="50%">
      <div className="flex-container-row item-details-container show">
        <div className="flex-container-row row-details">
          <div className="headline-small intro">This action will clear ALL your current changes.</div>
          <div className="headline-small intro">ARE YOU SURE YOU WANT TO CONTINUE?</div>
        </div>

        <div className="flex-container-row buttons">
          <PrimaryButton onClick={props.handleReset} name="Reset" />
          <SecondaryButton onClick={props.cancelResetModal} name="Cancel" />
        </div>
      </div>
    </Modal>
  </div>
);

export default ResetModal;