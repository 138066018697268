import getOriginalCountryOfOrigin from '../../utilities/country-of-origin';

const hasVendorItemUpdate = (item) => {
	let conditions = [];
	conditions.push(hasCountryOfOriginUpdate(item));
	conditions.push(hasQtyUpdate(item));
	conditions.push(hasPriceUpdate(item));
	conditions.push(hasWeightUpdate(item));
	return conditions.some(x => x === true);
}

const hasCountryOfOriginUpdate = (item) => {
	const hasSavedData = 'savedCoo' in item;
	const hasItem = item.item && item.item !== null;

	if (item.item && item.item !== null && 'newCountryOfOrigin' in item.item) {
		return item.item.newCountryOfOrigin !== getOriginalCountryOfOrigin(item);
	}

	return 'savedCoo' in item && item.savedCoo !== null && item.savedCoo !== getOriginalCountryOfOrigin(item);
}

const hasNewCountryOfOrigin = (item) => {
	return item.item && item.item !== null && 'newCountryOfOrigin' in item.item && item.item.newCountryOfOrigin !== null;	
	// return item.item && item.item !== null && 'newCountryOfOrigin' in item.item && item.item.newCountryOfOrigin !== getOriginalCountryOfOrigin(item);
}

const hasSavedCountryOfOrigin = (item) => {
	return 'savedCoo' in item && item.savedCoo !== null; // && item.savedCoo !== getOriginalCountryOfOrigin(item);
}

const hasVendorPoUpdate = (poItemDetails) => {
	return poItemDetails.some((item) => hasVendorItemUpdate(item))
}

const hasQtyUpdate = (item) => {
	if ('newQty' in item) {
		return parseInt(item.newQty) !== parseInt(item.qty);
	}
	
	return ('savedQty' in item && item.savedQty !== null && parseInt(item.savedQty) !== parseInt(item.qty));
}

const hasNewQty = (item) => {
	return 'newQty' in item && item.newQty !==  null;
	// return 'newQty' in item && parseInt(item.newQty) !== parseInt(item.qty);
}

const hasSavedQty = (item) => {
	return 'savedQty' in item && item.savedQty !== null; // && parseInt(item.savedQty) !== parseInt(item.qty);
}

const hasPriceUpdate = (item) => {
	if ('newPrice' in item) {
		return parseFloat(item.newPrice) !== parseFloat(item.price);
	}

	return 'savedPrice' in item && item.savedPrice !== null && parseFloat(item.savedPrice) !== parseFloat(item.price);
}

const hasNewPrice = (item) => {
	return 'newPrice' in item && item.newPrice !== null;
	// return 'newPrice' in item && parseFloat(item.newPrice) !== parseFloat(item.price);
}

const hasSavedPrice = (item) => {
	return 'savedPrice' in item && item.savedPrice !== null; // && parseFloat(item.savedPrice) !== parseFloat(item.price)
}

const hasWeightUpdate = (item) => {
	return 'newTotalWeight' in item && parseFloat(item.newTotalWeight) !== parseFloat(item.totalWeight);
}

const hasLoadDateUpdate = (currentPO, originalPO) => {
	return ('loadDate' in currentPO && formatDate(currentPO.loadDate) !== formatDate(originalPO.loadDate))
	|| ('savedLoadDate' in currentPO && currentPO.savedLoadDate !== null && formatDate(currentPO.savedLoadDate) !== formatDate(originalPO.loadDate));
}

const hasSavedLoadDate = (currentPO, originalPO) => {
	return 'savedLoadDate' in currentPO && currentPO.savedLoadDate !== null; // && formatDate(currentPO.savedLoadDate) !== formatDate(originalPO.loadDate);
}

const hasNewLoadDate = (currentPO, originalPO) => {
	return 'loadDate' in currentPO && formatDate(currentPO.loadDate) !== formatDate(originalPO.loadDate);
}

const hasArrivalDateUpdate = (currentPO, originalPO) => {
	return ('arrivalDate' in currentPO && formatDate(currentPO.arrivalDate) !== formatDate(originalPO.arrivalDate))
	|| ('savedArrivalDate' in currentPO && currentPO.savedArrivalDate !== null && formatDate(currentPO.savedArrivalDate) !== formatDate(originalPO.arrivalDate));
}

const hasNewArrivalDate = (currentPO, originalPO) => {
	return 'arrivalDate' in currentPO && formatDate(currentPO.arrivalDate) !== formatDate(originalPO.arrivalDate);
}

const hasSavedArrivalDate = (currentPO, originalPO) => {
	return 'savedArrivalDate' in currentPO && currentPO.savedArrivalDate !== null; // && formatDate(currentPO.savedArrivalDate) !== formatDate(originalPO.arrivalDate);
}

const hasCoolerUpdate = (currentPO, originalPO) => {
	const hasSavedData = 'savedCoolerCode' in originalPO;

	// Use Cases on Submit are:
    // 1. User does not change cooler code at all and submits... there will be no newCoolerCode, no savedCoolerCode, only coolerCode
    const coolerCodeHasChanged = !currentPO.newCoolerCode && !hasSavedData && currentPO.coolerCode !== originalPO.coolerCode;
        
	// 2. User selects a new cooler code from the dropdown and submits
	const newCoolerCodeHasChanged = 'newCoolerCode' in currentPO && !hasSavedData && currentPO.newCoolerCode !== originalPO.coolerCode;

	// 3. Saved record returned and user changes cooler code before submit
	const originalCoolerCode = originalPO.coolerCode === null ? "" : originalPO.coolerCode; // if user selected "Select a Cooler Code"
	const savedCoolerCodeHasChanges = hasSavedData && currentPO.newCoolerCode && currentPO.newCoolerCode !== originalCoolerCode;	

	// 4. Saved Cooler Code returned user does not change the cooler code before submitting - does not match the original P3 cooler code
	const savedCoolerCodeDoesNotMatchP3 = hasSavedData && !currentPO.newCoolerCode && currentPO.savedCoolerCode !== originalPO.coolerCode;
	
	return coolerCodeHasChanged || newCoolerCodeHasChanged || savedCoolerCodeHasChanges || savedCoolerCodeDoesNotMatchP3;
}

const hasNewCooler = (currentPO, originalPO) => {
	return 'newCoolerCode' in currentPO; // && currentPO.coolerCode !== originalPO.coolerCode;
}

const hasSavedCooler = (currentPO, originalPO) => {
	return 'savedCoolerCode' in currentPO && (currentPO.savedCoolerCode !== null); //&& currentPO.savedCoolerCode !== originalPO.coolerCode
}

const hasPickupNumberUpdate = (currentPO, originalPO) => {
	return currentPO.pickupNumber !== originalPO.pickupNumber
}

const hasNoteUpdate = (currentPO) => {
	return 'vendorNote' in currentPO && currentPO.vendorNote !== null;
}

const hasPoHeaderUpdate = (currentPO, originalPO) => {
    // check load date, arrival date, cooler, and note
    let conditions = [];
    conditions.push(hasLoadDateUpdate(currentPO, originalPO));
	conditions.push(hasArrivalDateUpdate(currentPO, originalPO));
	conditions.push(hasCoolerUpdate(currentPO, originalPO));
	return conditions.some(x => x === true);
}

const formatDate = (dt) => {
    return (typeof(dt) === 'string' && dt.includes('T')) ? dt.split('T')[0] : dt;
}

export { 
	hasVendorItemUpdate, 
	hasVendorPoUpdate, 
	hasPoHeaderUpdate, 
	hasQtyUpdate,
	hasNewQty,
	hasSavedQty,
	hasPriceUpdate,
	hasNewPrice,
	hasSavedPrice,
	hasWeightUpdate, 
	hasCountryOfOriginUpdate,
	hasNewCountryOfOrigin,
	hasSavedCountryOfOrigin,
	hasLoadDateUpdate,
	hasNewLoadDate,
	hasSavedLoadDate,
	hasArrivalDateUpdate,
	hasNewArrivalDate,
	hasSavedArrivalDate,
	hasCoolerUpdate,
	hasNewCooler,
	hasSavedCooler,
	hasPickupNumberUpdate,
	hasNoteUpdate
};
