/* eslint react/jsx-indent: 1 */
/* eslint react/jsx-indent-props: 1 */
/* eslint react/no-unused-state: 1 */
/* eslint no-undef: 1 */

import React from 'react';
import { Logger } from 'aws-amplify';
import currencyFormatter from '../utilities/currency-formatter';
import { 
	hasPickupNumberUpdate,
	hasNoteUpdate
} from '../services/validation/vendor-update-check';
const logger = new Logger('PurchaseOrderDetails.js');

class PurchaseOrderDetailsLine2 extends React.Component {
  constructor(props) {
    super(props);

    const po = this.props.po
    po.pickupNumber = (po.savedPickupNumber !== undefined && po.savedPickupNumber !== null) ? po.savedPickupNumber : po.pickupNumber
    this.state = {
      po: this.props.po,
      pickupNumber: (this.props.po.savedPickupNumber !== undefined && this.props.po.savedPickupNumber !== null) ? this.props.po.savedPickupNumber : this.props.po.pickupNumber,
    };

    this.handlePickUpNumberChange = this.handlePickUpNumberChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleNoteKeyPress = this.handleNoteKeyPress.bind(this);
    this.handleNoteBlur = this.handleNoteBlur.bind(this);
  }
  
  componentDidUpdate = (prevProps) => {
    const { searchId, po } = this.props;
    if (searchId !== prevProps.searchId) {
      po.pickupNumber = (po.savedPickupNumber !== undefined && po.savedPickupNumber !== null) ? po.savedPickupNumber : po.pickupNumber   
      this.setState({
        po: po,
        pickupNumber: po.pickupNumber
      });
    }
  }

  handlePickUpNumberChange = (e) => {
    const { po } = this.state;
    po.savedPickupNumber = null;
    const pickupNumber = e.target.value;
    po.pickupNumber = pickupNumber;
    this.setState({ po, pickupNumber });
    this.props.handlePoHeaderChange(po);
  }

  handleFocus = (e) => e.target.select();

  handleNoteKeyPress = (e) => {
    // User cannot enter characters in blockedChars array
    const blockedChars = ['Enter'];
    if (blockedChars.includes(e.key)) {
        e.preventDefault()
    }
  }

  handleNoteBlur = (e) => {
    {
        e.target.scrollTo(0,0);
        const { po } = this.state;
        po.ocaVendorNote = e.target.value.length > 0 ? e.target.value : null;
        this.props.handlePoHeaderChange(po);
    }
  }

  render() {
    const { po, pickupNumber } = this.state;

    const { originalPo, purchaseOrderItemDetails, buyer } = this.props;

    return (
        <div className="flex-container-row po-data po-item-count">
            <div className="flex-container-column top-level flex-grow">
                <div className="flex-container-row po-details-2">
                    <div className="flex-container-column">
                        Pickup Number:
                    </div>
                    <div className={hasPickupNumberUpdate(po, originalPo) ? 'flex-container-column pickup-number updated' : 'flex-container-column pickup-number'}>
                        <input className="po-input" id={`pickupNumber-${po.purchaseOrderId}`} onFocus={ this.handleFocus } type="text" value={pickupNumber === null ? '' : pickupNumber} onChange={this.handlePickUpNumberChange} />
                    </div>
                    <div className="flex-container-column">
                        Total Cases: {po.totalCartons}
                    </div>
                    <div className="flex-container-column">
                        Total Weight: {po.totalWeight}
                    </div>
                    <div className="flex-container-column">
                        Pallets: {po.totalPallets}
                    </div>
                    <div className="flex-container-column">
                        Total Cost: {currencyFormatter.format(po.totalCost)}
                    </div>
                    <div className="flex-container-column">
                        Buyer: {buyer[0].description}
                    </div>
                    <div className="flex-container-column">
                        Confirmation Notes:
                    </div>
                    <div className="flex-container-column flex-grow">
                        <div className={'po-note-container'}>
                            <textarea className="po-note readonly" maxLength="512" defaultValue={this.props.po.vendorNote ? this.props.po.vendorNote : null} readOnly={true}></textarea>
                        </div>
                    </div>
                    <div className="flex-container-column">
                        Vendor Notes:
                    </div>
                    <div className="flex-container-column flex-grow">
                        <div className={this.state.po.ocaVendorNote && this.state.po.ocaVendorNote.length > 0 ? 'po-note-container updated' : 'po-note-container'}>
                            <textarea className="po-note" maxLength="512" onKeyPress={ this.handleNoteKeyPress } onBlur={ this.handleNoteBlur } defaultValue={this.props.po.ocaVendorNote ? this.props.po.ocaVendorNote : null}></textarea>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex-container-column item-count top-level">
                <div style={{width: "125px", textAlign: "right"}}>
                    <button className="toggle-items" type="button" onClick={() => this.props.handleExpandCollapse(po.purchaseOrderId)}>Show {purchaseOrderItemDetails.filter(item => item.poId === po.purchaseOrderId).length === 0 ? '' : purchaseOrderItemDetails.filter(item => item.poId === po.purchaseOrderId).length} Items</button>
                </div>
            </div>
        </div>
    )}
}

export default PurchaseOrderDetailsLine2;
