/* eslint react/jsx-indent: 1 */
/* eslint react/jsx-indent-props: 1 */

import React from 'react';
import { Logger } from 'aws-amplify';
import {
  DropDown, TextInput
} from 'wf-react-component-library';
import currencyFormatter from '../utilities/currency-formatter';
import { validateItem } from '../services/validation/purchase-order-details-validation';
import getOriginalCountryOfOrigin from '../utilities/country-of-origin';

const logger = new Logger('PurchaseOrderItemDetails.js');

class PurchaseOrderItemDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      item: this.props.item
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate = (prevProps) => {
    const { searchId, item } = this.props;
    if (searchId !== prevProps.searchId) {
      item['newCountryOfOrigin'] = '';
      item['newQty'] = null;
      item['newPrice'] = null;
    }
  }

  handleChange = (e) => {
    var { item } = this.state;

    const val = e.target.value;
    const propName = e.target.id.split('-').length === 1 ? e.target.name.split('-')[0] : e.target.id.split('-')[0];

    if (propName === 'countryOfOrigin') {
        const hasItemProperty = item.item && item.item !== null;
        if (!hasItemProperty) item.item = {};
        item.item['newCountryOfOrigin'] = val;
    } else if (propName === 'qty') {
        item['newQty'] = val
    } else {
       item['newPrice'] = val
    }
    this.setState({ item });

    this.props.handleChange(item);
  }

  render() {
    const { item, qtyError, priceError} = this.state;
    const { countryOfOrigins } = this.props;

    const itemErrors = validateItem(item);
    const { qtyErrors, priceErrors } = itemErrors;

    const countryOfOrigin = getOriginalCountryOfOrigin(item);

    const hasErrors = (errors, error) => errors.includes(item.purchaseOrderDetailId.toString()) || error;

    // check for updates while user is editing inline
    const hasQtyUpdates = (item, ocaPropName, p3PropName) => {
      if (item[ocaPropName] !== undefined && item[ocaPropName] !== null && item[ocaPropName].toString() !== item[p3PropName].toString()) return true;
      return false;
    }

    // check for updates while user is editing inline
    const hasPriceUpdates = (item, ocaPropName, p3PropName) => {
      if (item[ocaPropName] !== undefined && item[ocaPropName] !== null && parseFloat(item[ocaPropName]).toFixed(2) !== parseFloat(item[p3PropName]).toFixed(2)) return true;
      return false;
    }

    // If no new value has been entered, then check the saved value when component rendered
    const hasSavedQtyUpdates = (item, ocaSavedPropName, ocaNewPropName, p3PropName) => {
      if ((item[ocaNewPropName] === undefined || item[ocaNewPropName] === null) && item[ocaSavedPropName] !== undefined && item[ocaSavedPropName] !== null && item[ocaSavedPropName].toString() !== item[p3PropName].toString()) return true;
      return false;
    }

    // If no new value has been entered, then check the saved value when component rendered
    const hasSavedPriceUpdates = (item, ocaSavedPropName, ocaNewPropName, p3PropName) => {
      if ((item[ocaNewPropName] === undefined || item[ocaNewPropName] === null) && item[ocaSavedPropName] !== undefined && item[ocaSavedPropName] !== null && parseFloat(item[ocaSavedPropName]).toFixed(2) !== parseFloat(item[p3PropName]).toFixed(2)) return true;
      return false;
    }

    const isBurdenItem = (item.item.itemGroupCode === "M" || item.item.itemGroupCode === "MB" || item.item.itemGroupCode === "SB") ? true : false;
    const qtyClassName = hasErrors(qtyErrors, qtyError) ? 'item-details-col qty error' : 
      ((hasSavedQtyUpdates(item, 'savedQty', 'newQty', 'arQty') || hasQtyUpdates(item, 'newQty', 'arQty')) ? 'item-details-col qty updated' : 'item-details-col qty');
    
    const priceClassName = hasErrors(priceErrors, priceError) ? 'item-details-col price error' : 
      ((hasSavedPriceUpdates(item, 'savedPrice', 'newPrice', 'price') || hasPriceUpdates(item, 'newPrice', 'price')) ? 'item-details-col price updated' : 'item-details-col price');
    
    const cooClassName = (item.item && item.item.newCountryOfOrigin && item.item.newCountryOfOrigin !== item.item.originName)
      || (item.item && !item.item.newCountryOfOrigin && item.savedCoo !== undefined && item.savedCoo !== null && item.savedCoo !== item.item.originName) 
        ? "flex-container-column coo updated" : "flex-container-column coo";

    
    

    return (
        <td style={{width: '100%'}} colSpan={9}>
            <table cellPadding="0" cellSpacing="0" width="100%">
                <colgroup>
                    <col span="1" style={{ width: '8%' }} />
                    <col span="1" style={{ width: '30%' }} />
                    <col span="1" style={{ width: '4%' }} />
                    <col span="1" style={{ width: '4%' }} />
                    <col span="1" style={{ width: '4%' }} />
                    <col span="1" style={{ width: '4%' }} />
                    <col span="1" style={{ width: '10%' }} />
                    <col span="1" style={{ width: '4%' }} />
                    <col span="1" style={{ width: '4%' }} />
                </colgroup>
                <tbody>
                    <tr className="item-details-row">
                        <td>{item.item ? item.item.itemCode : null}</td>
                        <td>{item.item ? item.item.description : item.itemDesc}</td>
                        <td>{item.arQty}</td>
                        <td className={qtyClassName}>
                          <TextInput value={item.newQty !== undefined && item.newQty !== null ? item.newQty.toString() : (item.savedQty !== undefined && item.savedQty !== null) ? item.savedQty.toString() : item.qty.toString()} handleChange={this.handleChange} className="po-input item-details qty" id={`qty-${item.purchaseOrderDetailId}`} type="text" />
                        </td>
                        <td>{item.grossWeight}</td>

                        <td> {isBurdenItem ? null : countryOfOrigin} </td>
                        <td className="item-details-col">
                            <div className={cooClassName}>
                              { isBurdenItem ? null
                              : <DropDown handleChange={this.handleChange} value={(item.item && item.item.newCountryOfOrigin) ? item.item.newCountryOfOrigin : (item.savedCoo !== undefined && item.savedCoo !== null) ? item.savedCoo : countryOfOrigin} id={`countryOfOrigin-${item.purchaseOrderDetailId}`} options={countryOfOrigins} placeHolderText="Select a COO"  />
                              }
                            </div>
                        </td>             
                        <td>{currencyFormatter.format(item.price)}</td>
                        <td className={priceClassName}>
                          <TextInput value={item.newPrice !== undefined && item.newPrice !== null ? item.newPrice.toString() : (item.savedPrice !== undefined && item.savedPrice !== null) ? currencyFormatter.format(item.savedPrice).replace('$','') : currencyFormatter.format(item.price).replace('$','')} handleChange={this.handleChange} className="po-input item-details price" id={`price-${item.purchaseOrderDetailId}`} type="text" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </td>
    );
  }
}

export default PurchaseOrderItemDetail;
