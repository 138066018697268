/* eslint react/no-unused-state: 1 */
/* eslint no-undef: 1 */

import 'react-dates/initialize';
import React from 'react';
import { Logger } from 'aws-amplify';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import {
  DropDown, PrimaryButton, Pulse
} from 'wf-react-component-library';
import PurchaseOrderDetails from './PurchaseOrderDetails';
import ErrorModal from './ErrorModal';
import { getPurchaseOrdersBySearchCriteria } from '../services/api/OCAApiService';

import 'react-dates/lib/css/_datepicker.css';
import './Search.scss';

import StickyWrapper from './StickyWrapper';

const logger = new Logger('Search.js');

class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCustomers: [],
      vendorDetails: this.props.vendorDetails,
      dateOptions: {
        0: 'Load Date',
        1: 'Arrival Date',
      },
      startDate: null,
      endDate: null,
      dateType: null,
      isSearching: false,
      isLoadingCustomers: false,
      displayNoItemsFound: false,
      errorMsg: '',
      showSearchBar: true
    }

    this.handleSearch = this.handleSearch.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDropDownChange = this.handleDropDownChange.bind(this);
    this.resetPurchaseOrderDetails = this.resetPurchaseOrderDetails.bind(this);
    this.removeErrorMessages = this.removeErrorMessages.bind(this);
    this.handleHideShowSearchBar = this.handleHideShowSearchBar.bind(this);
  }

  componentDidMount = () => {
    this.handleSearch();
  }

  handleSearch = (buttonClick) => {
    const { dateType, startDate, endDate } = this.state;
    
    //Check for dateType and at least one date is selected
    const dateSelected = [startDate, endDate].some(val => val !== null);
    const typeSelected = ['0', '1'].includes(dateType);
    if (buttonClick && dateSelected && !typeSelected) {
      this.setState({ errorMsg: 'Please select a date for your search or click the "Reset" button to return all Purchase Orders.' });
      return;
    }

    this.setState({ isSearching: true, displayNoItemsFound: false });

    const params = {
      startDate: startDate === null ? null : startDate.format('YYYY-MM-DD'),
      endDate: endDate === null ? null : endDate.format('YYYY-MM-DD'),
      dateType
    };

    getPurchaseOrdersBySearchCriteria(params).then((purchaseOrderDetailsData) => {
      try {
        this.setState({ isSearching: false }); 
        const purchaseOrderDetails = purchaseOrderDetailsData.purchaseOrders;
        const customerCodesOnPOs = purchaseOrderDetailsData.customers;
        const buyerIdsOnPOs = purchaseOrderDetailsData.buyers;
        const customerDetails = this.props.customerDetails
                                          .filter(c => customerCodesOnPOs.includes(c.code))
                                          .map(i => ({
                                            customerCode: i.code,
                                            description: i.description
                                          }));
        const buyerDetails = this.props.buyerDetails.filter(b => buyerIdsOnPOs.includes(b.buyerId));
        
        this.setState({ purchaseOrderDetails, customerDetails, buyerDetails, displayNoItemsFound: purchaseOrderDetails.length === 0 });      
      } catch {
        this.setState({ purchaseOrderDetails: [], displayNoItemsFound: true, isSearching: false });
      }
    });
  }

  updateSearch = () => {
    this.handleSearch(true);
  }

  handleDropDownChange = (e) => {
    this.resetPurchaseOrderDetails();
    this.setState({ dateType: e.target.value });
  }

  resetPurchaseOrderDetails = () => {
    const { purchaseOrderDetails } = this.state;

    this.setState({
      isSearching: false,
      displayNoItemsFound: false
    });

    if (purchaseOrderDetails !== undefined && purchaseOrderDetails.length > 0) {
      this.setState({
        purchaseOrderDetails: [],
      });
    }
  }

  handleReset = () => {
    this.setState({
      customerDetails: [],
      buyerDetails: [],
      dateType: null,
      startDate: null,
      endDate: null,
      purchaseOrderDetails: [],
      isSearching: false,
      displayNoItemsFound: false
    },
    () => this.handleSearch());
  }

  removeErrorMessages = () => this.setState({ errorMsg: '' });

  handleHideShowSearchBar = () => {
    const { showSearchBar } = this.state;
    this.setState({ showSearchBar: !showSearchBar});
  }

  render() {
    const {
      customerDetails,
      buyerDetails,
      dateOptions,
      purchaseOrderDetails,
      isSearching,
      displayNoItemsFound,
      dateType,
      vendorDetails,
      showSearchBar
    } = this.state;

    const { currentUser, countryOfOrigins, vendorCoolers } = this.props;

    return (
      <div className="App">
        { this.state.errorMsg.length > 0
          ? <ErrorModal showErrorModal={this.state.errorMsg.length > 0} errorMessages={this.state.errorMsg} closeModal={this.removeErrorMessages} />
          : null
        }

            

        <StickyWrapper>
            { vendorDetails === undefined
              ? null
              : <div className="flex-container-row vendor-title">
                  <div className="flex-container-column">
                    Viewing Purchase Orders for: {vendorDetails.description} ({vendorDetails.code})
                  </div>
                  <div className="flex-container-column">
                    <button className="toggle-search-bar" type="button" onClick={this.handleHideShowSearchBar}>{ showSearchBar ? 'Hide' : 'Show' } Search Bar</button>
                  </div>
              </div>
            }

            { showSearchBar
              ? <div className="search-criteria flex-container-row">
                <div className="flex-container-column" style={{ paddingBottom: '30px' }}>
                  <DropDown id="dateOptions" labelName="Date" options={dateOptions} value={dateType === null ? '' : dateType} placeHolderText="Select a Date Option" handleChange={this.handleDropDownChange} />
                </div>

                <div className="flex-container-column" style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '30px' }}>
                  <div className="wf-form-element-label">
                    From:
                    <span style={{ paddingLeft: '110px' }}>To:</span>
                  </div>
                  <DateRangePicker
                    startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                    startDateId="start-dt" // PropTypes.string.isRequired,
                    endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                    endDateId="end-dt" // PropTypes.string.isRequired,
                    onDatesChange={({ startDate, endDate }) => {
                      this.resetPurchaseOrderDetails();
                      this.setState({ startDate, endDate })}
                    }
                    focusedInput={this.state.focusedInput}
                    onFocusChange={(focusedInput) => this.setState({ focusedInput })}
                    isOutsideRange={(day) => day.isBefore(moment().subtract(3, 'month'))}
                    minimumNights={0}
                    readOnly={true}
                  />
                </div>

                <div className="flex-container-column">
                  <div className="flex-container-row search-buttons">
                    <PrimaryButton onClick={this.updateSearch} name="Search" />
                    <PrimaryButton onClick={this.handleReset} name="Reset" />
                  </div>
                </div>
              </div>
              : null
          }
          </StickyWrapper>

        { isSearching
          ? <div className="flex-container-row"><Pulse text="Loading Purchase Orders..." /></div>
          : null
        }

        { displayNoItemsFound
          ? <div className="flex-container-row">
              <div className="pulse-container headline-small">No POs Found</div>
            </div>
          : null
        }

        { isSearching || purchaseOrderDetails === undefined || purchaseOrderDetails.length === 0
          ? null
          : <PurchaseOrderDetails handleSearch={this.handleSearch} customerDetails={customerDetails} buyerDetails={buyerDetails} searchId={Math.floor(1000000 * Math.random()) * 100000000} currentUser={currentUser} purchaseOrderDetails={purchaseOrderDetails} countryOfOrigins={countryOfOrigins} vendorCoolers={vendorCoolers}  />
        }
      </div>
    );
  }
}

export default Search;
